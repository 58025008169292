import React, { useMemo } from 'react'
import { NumerosAnuaisTable } from './components/_NumerosAnuaisTable'

import { SelectOption, SingleValue } from '@interco/inter-ui/components/Select'
import SearchBar from './components/_SearchBar'
import { ArraySlice, useArraySlice } from './hooks/_useArraySlice'
import { useTableData } from './hooks/_useTableData'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import ErrorBoundary from './components/_ErrorBoundary'
import ErrorView from './components/_ErrorView'
import { Theme, initTheme } from '@interco/inter-ui'
import { SearchData } from './_types'
import { NumerosAnuaisData, NumerosAnuaisService } from 'src/services/numeros-anuais-compacto/NumerosAnuaisService'

const NumerosAnuaisCompacto = () => {
  const [ currentSelection, setCurrentSelection ] = React.useState<
    SingleValue<SelectOption>
  >({ label: 'CPF', value: 'cpf' })
  const service = useMemo(() => new NumerosAnuaisService(), [])
  const { start, end, updateSlice }: ArraySlice = useArraySlice(0, 499)
  const [ tableData, setTableData, isFetching, setIsFetching ] = useTableData(
    service,
    start,
    end,
  )
  const [ isSearchActive, setIsSearchActive ] = React.useState<boolean>(false)

  const setIsLoading = () => {
    setTableData([])
    setIsFetching(true)
  }

  const setIsDoneLoading = (res: NumerosAnuaisData[][] | undefined) => {
    setTableData(res || [])
    setIsFetching(false)
  }

  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const handleOnSearch = (data: SearchData) => {
    setIsSearchActive(true)
    const { searchTerm, firstFourDigits, lastTwoDigits, searchOption }: SearchData = data
    const searchData = async () => {
      setIsLoading()
      switch (searchOption?.value) {
        case 'cpf':
          {
            if (!firstFourDigits || !lastTwoDigits) return
            const res = await service.findByCpf(firstFourDigits + lastTwoDigits)
            setIsDoneLoading(res)
          }
          break
        case 'name': {
          if (!searchTerm) return
          const res = await service.findByName(searchTerm)
          setIsDoneLoading(res)
          break
        }
        case 'city': {
          if (!searchTerm) return
          const res = await service.findByCity(searchTerm)
          setIsDoneLoading(res)
          break
        }
        case 'luckyNumber': {
          if (!searchTerm) return
          const res = await service.findByLuckyNumber(searchTerm)
          setIsDoneLoading(res)
          break
        }
      }
    }
    searchData()
  }

  const handleLastPage = () => {
    if (!isSearchActive) updateSlice(end + 1, end + 500)
  }

  const handleOnRestart = async () => {
    setIsLoading()
    if (isSearchActive) {
      const res = await service.get(start, end)
      setIsFetching(false)
      setIsDoneLoading(res)
    }
  }

  return (
    <Layout pageContext={pageContext}>
      <div className='container'>
        <div className='row py-5'>
          <div className='col-12'>
            <h1 className='mx-auto d-block fs-18 font-citrina fw-600 text-center mb-3'>
              Promoção Tesouro Premiado 2024 <br />
              Números da Sorte - Sorteio Anual
            </h1>
            <p>
              Promoção vigente de 15/01/2024 até 31/12/2024, conforme regulamento
              disponível em
              <a href='https://inter.co/tesouro-premiado/'>
                {' '}
                inter.co/tesouro-premiado/
              </a>
            </p>
          </div>
          <ErrorBoundary fallback={<ErrorView />}>
            <SearchBar
              setSelectedOption={setCurrentSelection}
              selectedOption={currentSelection}
              onSearchListener={handleOnSearch}
              disableSearchButton={isFetching}
              disableResetButton={!isSearchActive}
              onRestartListener={handleOnRestart}
            />
            <NumerosAnuaisTable
              isLoading={isFetching}
              data={tableData}
              onLastPageListener={handleLastPage}
            />
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  )
}

export default NumerosAnuaisCompacto
