import { useState } from 'react'
import { SearchBarFields, SearchOptions } from '../_types'
import getValidation from '../input-utils/_Validation'

export type SearchBarValidationReturn = {
  isStateValid: boolean;
  validateState: (state: SearchBarFields) => void;
}

export const useSearchBarValidation = (): SearchBarValidationReturn => {
  const [ areFieldsValid, setAreFieldsValid ] = useState<boolean>(false)
  const validateState = (state: SearchBarFields) => {
    const validationFn = getValidation(state.searchOption as SearchOptions)?.validation
    setAreFieldsValid(!!(validationFn && validationFn(state)))
  }

  return {
    isStateValid: areFieldsValid,
    validateState,
  }
}
