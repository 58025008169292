import axios from 'axios'

export type NumerosAnuaisData = {
  cpf: string;
  nome: string;
  cidade: string;
  numeroDaSorte: number;
}

export class NumerosAnuaisService {
  private url: string = process.env.INTER_GATEWAY_ANNUAL_NUMBERS as string
  private queryStart: string = 'start='
  private queryEnd: string = 'end='

  async get (startIndex: number = 0, endIndex: number = 499): Promise<NumerosAnuaisData[][] | undefined> {
    try {
      const response = await axios.get(`${this.url}?${this.queryStart}${startIndex}&${this.queryEnd}${endIndex}`)
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  async findByCpf (cpf: string): Promise<NumerosAnuaisData[][] | undefined> {
    try {
      const response = await axios.get(`${this.url}/cpf/${cpf}`)
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  async findByName (name: string): Promise<NumerosAnuaisData[][] | undefined> {
    try {
      const response = await axios.get(`${this.url}/name/${name}`)
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  async findByCity (city: string): Promise<NumerosAnuaisData[][] | undefined> {
    try {
      const response = await axios.get(`${this.url}/city/${city}`)
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  async findByLuckyNumber (luckyNumber: string): Promise<NumerosAnuaisData[][] | undefined> {
    try {
      const response = await axios.get(`${this.url}/lucky-number/${luckyNumber}`)
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
}
