import React, { useEffect, useState } from 'react'
import { Input } from '@interco/inter-ui/components/Input'
import { Select, SelectOption, SingleValue } from '@interco/inter-ui/components/Select'
import { Button } from '@interco/inter-ui/components/Button'
import { Controller, useForm } from 'react-hook-form'

import { SearchOptions, SearchBarProps, DynamicInputProps, SearchBarFields, DynamicInputState } from '../_types'
import getValidation from '../input-utils/_Validation'
import * as S from '../_styles'
import useWidth from 'src/hooks/window/useWidth'
import getErrorMessage from '../input-utils/_ErrorMessages'
import { SearchBarValidationReturn, useSearchBarValidation } from '../hooks/_useSearchBarValidation'

const options: SelectOption[] = [
  { label: 'CPF', value: 'cpf' },
  { label: 'Nome', value: 'name' },
  { label: 'Cidade', value: 'city' },
  { label: 'Número da sorte (anual)', value: 'luckyNumber' },
]

const SearchBar = ({
  setSelectedOption,
  selectedOption,
  onSearchListener,
  onRestartListener,
  disableResetButton = true,
  disableSearchButton: overrideCondition = false,
}: SearchBarProps) => {
  // eslint-disable-next-line @typescript-eslint/typedef
  const { register, handleSubmit, control } = useForm<SearchBarFields>()
  const { isStateValid, validateState }: SearchBarValidationReturn = useSearchBarValidation()
  const width = useWidth()

  const onSelectChangeHandler = (selectOption: SingleValue<SelectOption>) => {
    setSelectedOption(selectOption)
  }
  return (
    <div className='col-12'>
      <S.SearchBarForm
        onSubmit={handleSubmit(onSearchListener)}
      >
        <Controller
          name='searchOption'
          control={control}
          defaultValue={selectedOption}
          // eslint-disable-next-line @typescript-eslint/typedef
          render={({ onChange }) => (
            (
              <Select
                id='search-bar-select'
                label='Campo de busca'
                options={options}
                name='searchOption'
                placeholder='Selecionar...'
                value={selectedOption}
                onChange={(selectOption: SingleValue<SelectOption>) => {
                  onSelectChangeHandler(selectOption)
                  onChange(selectOption)
                }}
                styles={S.selectStyles(width)}
              />
            )
          )}
        />
        <SearchBar.DynamicInput
          shouldCleanFields={disableResetButton}
          selectedOption={selectedOption}
          register={register}
          onFieldChange={(dynamicInputState: DynamicInputState) =>
            validateState({ ...dynamicInputState, searchOption: selectedOption?.value as SearchOptions })
          }
        />
        <S.ActionBar>
          <Button
            type='button'
            disabled={disableResetButton}
            onClick={() => onRestartListener && onRestartListener()}
            style={S.actionBarButton(width)}
          >
            Reiniciar
          </Button>
          <Button
            type='submit'
            disabled={!isStateValid || overrideCondition}
            style={S.actionBarButton(width)}
          >
            Pesquisar
          </Button>
        </S.ActionBar>
      </S.SearchBarForm>
    </div>
  )
}

SearchBar.DynamicInput = ({ selectedOption, register, shouldCleanFields, onFieldChange }: DynamicInputProps) => {
  const width = useWidth()
  const initialState = {
    searchTerm: '',
    searchTermHasError: false,
    firstFourDigits: '',
    firstFourDigitsHasError: false,
    lastTwoDigits: '',
    lastTwoDigitsHasError: false,
  }

const [ state, setState ] = useState(initialState)

const updateState = (newState) => {
  setState(prevState => ({ ...prevState, ...newState }))
}

  useEffect(() => {
    if (shouldCleanFields) {
      const cleanState = { searchTerm: '', firstFourDigits: '', lastTwoDigits: '' }
      updateState(cleanState)
      onFieldChange(cleanState)
    }
  }, [ shouldCleanFields ])

  const handleSingleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value }: HTMLInputElement = event.currentTarget
    const newState = { firstFourDigits: state.firstFourDigits, lastTwoDigits: state.lastTwoDigits, searchTerm: value }
    const isFieldValid = getValidation(selectedOption?.value as SearchOptions).validation
    updateState({
      searchTerm: value,
      searchTermHasError: !isFieldValid(newState),
})
    onFieldChange(newState)
  }

  const handleFirstFourDigitsChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value }: HTMLInputElement = e.currentTarget
    const newState = { firstFourDigits: value, lastTwoDigits: state.lastTwoDigits, searchTerm: state.searchTerm }
    const isFieldValid = getValidation('firstFour').validation
    updateState({
      firstFourDigits: value,
      firstFourDigitsHasError: !isFieldValid(newState),
    })
    onFieldChange(newState)
  }

  const handleLastTwoChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value }: HTMLInputElement = e.currentTarget
    const newState = { firstFourDigits: state.firstFourDigits, lastTwoDigits: value, searchTerm: state.searchTerm }
    const isFieldValid = getValidation('lastTwo').validation
    updateState({
      lastTwoDigits: value,
      lastTwoDigitsHasError: !isFieldValid(newState),
    })
    onFieldChange(newState)
  }

  if (selectedOption?.value === 'cpf') {
    return (
      <S.FieldsContainer>
        <S.CpfInputWrapper>
          <S.SingleInputBox>
            <Input
              ref={register}
              name='firstFourDigits'
              id='search-bar-cpf-first-four'
              label='Primeiros 4 dígitos'
              placeholder='Formato XXXX'
              value={state.firstFourDigits}
              type='number'
              onChange={handleFirstFourDigitsChange}
              style={S.inputStyles(width)}
              error={state.firstFourDigitsHasError}
            />
            {state.firstFourDigitsHasError && (
              <S.ErrorMessage>
                {getErrorMessage('firstFour')}
              </S.ErrorMessage>
            )}
          </S.SingleInputBox>
          <S.SingleInputBox>
            <Input
              ref={register}
              name='lastTwoDigits'
              id='search-bar-last-two'
              type='number'
              label='Últimos 2 dígitos'
              placeholder='Formato XX'
              value={state.lastTwoDigits}
              onChange={handleLastTwoChange}
              style={S.inputStyles(width)}
              error={state.lastTwoDigitsHasError}
            />
            {state.lastTwoDigitsHasError && (
              <S.ErrorMessage>
                {getErrorMessage('lastTwo')}
              </S.ErrorMessage>
              )}
          </S.SingleInputBox>
        </S.CpfInputWrapper>
      </S.FieldsContainer>
    )
  }

  return (
    <S.FieldsContainer>
      <S.SingleInputBox>
        <Input
          name='searchTerm'
          ref={register}
          id='search-bar-input'
          label='Busca'
          placeholder={`Buscar por ${selectedOption?.label}`}
          value={state.searchTerm}
          onChange={handleSingleInputChange}
          style={S.inputStyles(width)}
          error={state.searchTermHasError}
        />
        {state.searchTermHasError && (
          <S.ErrorMessage>
            {getErrorMessage(selectedOption?.value as SearchOptions)}
          </S.ErrorMessage>
      )}
      </S.SingleInputBox>
    </S.FieldsContainer>
  )
}

export default SearchBar
