import { CpfCompositionT, SearchOptions } from '../_types'

const getErrorMessage = (type: Omit<SearchOptions, 'cpf'> | CpfCompositionT): string => {
    switch (type) {
      case 'firstFour': {
        return 'O campo deve conter 4 dígitos'
      }
      case 'lastTwo':
        return 'O campo deve conter 2 dígitos'
      case 'name':
      case 'city':
        return 'O campo não pode estar vazio e deve conter apenas letras'
      case 'luckyNumber':
        return 'O campo não pode estar vazio e deve conter apenas números'
      default:
        throw new Error(`Tipo ${type} não reconhecido`)
    }
  }

export default getErrorMessage
