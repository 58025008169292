import { CpfCompositionT, InputValidationT, SearchBarFields, SearchOptions } from '../_types'

const getValidation = (type: SearchOptions | CpfCompositionT): InputValidationT => {
    switch (type) {
      case 'firstFour':
        return {
          validation: (state: SearchBarFields) => {
            if (!state.firstFourDigits) return false
            return state.firstFourDigits.length === 4
          },
        }
      case 'lastTwo':
        return {
          validation: (state: SearchBarFields) => {
            if (!state.lastTwoDigits) return false
            return state.lastTwoDigits.length === 2
          },
        }
      case 'cpf':
        return {
          validation: (state: SearchBarFields) => {
            if (!state.firstFourDigits || !state.lastTwoDigits) return false
            return state.firstFourDigits.length === 4 && state.lastTwoDigits.length === 2
          },
        }
      case 'name':
      case 'city':
        return {
          validation: (state: SearchBarFields) => {
            if (!state.searchTerm) return false
            const validation = new RegExp(/^[a-zA-Z\s]+$/)
            return validation.test(state.searchTerm)
          },
        }

      case 'luckyNumber':
        return {
          validation: (state: SearchBarFields) => {
            if (!state.searchTerm) return false
            const validation = new RegExp(/^[0-9]+$/)
            return validation.test(state.searchTerm)
},
        }
      default:
        throw new Error(`Tipo ${type} não reconhecido`)
    }
  }

export default getValidation
