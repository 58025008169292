import React from 'react'
import { NumerosAnuaisProps } from '../_types'
import { Table } from '@interco/inter-ui/components/Table'
import { Pagination } from './_Pagination'

export const NumerosAnuaisTable: React.FC<NumerosAnuaisProps> = ({ data, onLastPageListener, isLoading = false }: NumerosAnuaisProps) => {
  const [ currentPage, setCurrentPage ] = React.useState(0)

  const tableData = data.length ? data[currentPage] : []

  const onPageChangeHandler = (page: number) => {
    setCurrentPage(page)
    if (page === data.length - 1) {
      onLastPageListener && onLastPageListener()
    }
  }

  return (
    <>
      <div className='row d-block mx-auto mb-3'>
        <Pagination isLoading={isLoading} currentPage={currentPage} totalPages={data.length} onPageChange={onPageChangeHandler} />
      </div>

      <Table
        columns={
          [ { Header: 'CPF', accessor: 'cpf' },
          { Header: 'Nome', accessor: 'nome' },
          { Header: 'Cidade', accessor: 'cidade' },
          { Header: 'Número da Sorte (Anual)', accessor: 'numeroDaSorte' } ]
        }
        data={tableData}
        error='Erro ao carregar os dados'
        maxHeight='auto'
        styleType='default'
        isLoading={isLoading}
      />

      <div className='row d-block mx-auto mt-3'>
        <Pagination isLoading={isLoading} currentPage={currentPage} totalPages={data.length} onPageChange={onPageChangeHandler} />
      </div>

    </>
  )
}
