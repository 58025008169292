import React, { ComponentClass, FunctionComponent, useMemo } from 'react'
import { IconsSwitchProps } from './types'
import Loadable, { LoadableComponent } from 'react-loadable'
import { LoadingIndicator } from '../LoadingIndicator/style'

const DinamicIcon = ({ icon, size, color, className, customPath }: IconsSwitchProps) => {
  const SvgIcon: (ComponentClass<IconsSwitchProps> & LoadableComponent)
    | (FunctionComponent<IconsSwitchProps> & LoadableComponent) = useMemo(() => {
      return (
        Loadable({
          loader: () => import(`@interco/icons/${customPath}${icon}`),
          loading: () => <LoadingIndicator size='small' />,
        })
      )
  }, [ icon ])

  return (
    <SvgIcon
      icon={icon}
      size={size}
      color={color}
      className={className}
      customPath={customPath}
    />
  )
}

export default DinamicIcon
