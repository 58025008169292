import React from 'react'
import { NumerosAnuaisService } from '../services/_NumerosAnuaisService'
import { ITableData } from '../_types'

type useTableDataReturn = [ ITableData[][], React.Dispatch<React.SetStateAction<ITableData[][]>>, boolean, React.Dispatch<React.SetStateAction<boolean>> ]

export const useTableData = (service: NumerosAnuaisService, start: number, end: number): useTableDataReturn => {
  const [ tableData, setTableData ] = React.useState<ITableData[][]>([])
  const [ isFetching, setIsFetching ] = React.useState(false)

  React.useEffect(() => {
    setIsFetching(true)
    const fetchData = async () => {
      const res = await service.get(start, end)
      if (res && res.length === 0) {
        setTableData([])
      } else if (res && res.length > 0) {
        setTableData((prevData: ITableData[][]) => [ ...prevData, ...res ])
      }
      setIsFetching(false)
    }
    fetchData()
  }, [ start, end ])

  return [ tableData, setTableData, isFetching, setIsFetching ]
}
