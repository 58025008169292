import { useState } from 'react'

export type ArraySlice = {
  start: number;
  end: number;
  updateSlice: (newStart: number, newEnd: number) => void;

}
export const useArraySlice = (initialStart: number, initialEnd: number) => {
  const [ start, setStart ] = useState(initialStart)
  const [ end, setEnd ] = useState(initialEnd)

  const updateSlice = (newStart: number, newEnd: number) => {
    setStart(newStart)
    setEnd(newEnd)
  }

  return { start, end, updateSlice }
}
