import { WIDTH_LG, WIDTH_MD, breakpoints } from 'src/styles/breakpoints'
import { red } from 'src/styles/colors'
import styled from 'styled-components'

export const SearchBarForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  // search field
  &> div:first-child {
    width: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    justify-content: left;
    align-items: flex-start;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 100px;
    flex-direction: row;
    width: 100%;
    max-width: unset;
    // search field
    &>div:first-child {
      max-width: 200px !important;
      margin-right: 1rem;
    }
  }
`
export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 1rem;
    margin-bottom: 0;
    width: 100%;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
  }
`

export const SingleInputBox = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  min-height: 100px;
`

export const CpfInputWrapper = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    ${SingleInputBox}:first-child{
      margin-right: 1rem;
      width: 100%;
    }
  }
`

export const ErrorMessage = styled.div`
  color: ${red[500]};
  font-size: 13px;
`

export const selectStyles = (width: number) => {
  return {
    container: (baseStyles: any) => ({
      ...baseStyles,
      marginBottom: width < WIDTH_MD ? '1rem' : '0',
    }),
  }
}

export const inputStyles = (width: number) => {
  return {
    padding: '0',
    marginRight: width >= WIDTH_MD ? '1rem' : '0',
    minWidth: '150px',
    width: '100%',
  }
}

export const actionBarButton = (width: number) => ({
  width: '100%',
  maxWidth: width >= WIDTH_LG ? '100px' : '150px',
  marginRight: width >= WIDTH_MD ? '0.5rem' : '0',
})

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

  @media (min-width: ${breakpoints.md}) {
    justify-content: center;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: fit-content;
    margin-left: 1rem;
  }
`
